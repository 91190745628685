import React from 'react';
import { object } from 'prop-types';
import Layout from '../components/Layout';
import { allComponents, SEO } from '../components';
import website from '../../config/website';
import { withPreview } from 'gatsby-source-prismic';

const getTitle = ({ title }) => {
  if (title) {
    return title.text + ' | ' + website._title;
  } else {
    return website._title;
  }
};

const PageConstructor = ({ pageContext, data }) => {
  if (data && data[Object.keys(data)[0]]) {
    let newData = data[Object.keys(data)[0]];
    let newArr = [];
    // Preview data is loaded via proxy and we need to loop through it to get it loaded
    newData.data.body.forEach(element => {
      newArr.push(element);
    });
    pageContext.names = newArr; // Inject it back into the main component array
    pageContext.pageLang = data.data.pageLang;
    pageContext.articleTags = newData.data.articleTags;
    pageContext.title = newData.data.meta_title;
    pageContext.description = newData.data.meta_description;
    pageContext.robots = newData.data.robots;
    pageContext.newsletter = newData.data.newsletter;
    pageContext.faq_link = newData.data.faq_link;
  }

  return (
    <Layout
      pageType={pageContext.type}
      faq_link={pageContext.faq_link ? pageContext.faq_link.uid : null}
      pageLang={pageContext.pageLang}
      newsletter={pageContext.newsletter}
    >
      <SEO
        title={`${getTitle(pageContext)}`}
        robots={pageContext.robots ? pageContext.robots : null}
        description={pageContext.description ? pageContext.description : null}
      />
      {pageContext.names.map((el, i) => {
        const postfix = el ? el.__typename.slice(-5) : null;
        const Component = allComponents[postfix];
        return !Component ? null : (
          <Component key={i} primary={el.primary} items={el.items} pageLang={pageContext.pageLang} />
        );
      })}
    </Layout>
  );

};

PageConstructor.propTypes = {
  pageContext: object.isRequired
};

export default withPreview(PageConstructor);
